export const SET_COMMERCE_CATALOGS_LOADER = 'SET_COMMERCE_CATALOGS_LOADER'

export const SET_COMMERCE_CATALOGS = 'SET_COMMERCE_CATALOGS'

export const SET_COMMERCE_PRODUCTS = 'SET_COMMERCE_PRODUCTS'

export const SET_COMMERCE_PRODUCTS_PAGE = 'SET_COMMERCE_PRODUCTS_PAGE'

export const SET_COMMERCE_ALL_PRODUCTS_LOADED =
  'SET_COMMERCE_ALL_PRODUCTS_LOADED'

export const SET_COMMERCE_PRODUCTS_PAGINATE_LOADER =
  'SET_COMMERCE_PRODUCTS_PAGINATE_LOADER'

export const SET_COMMERCE_PRODUCT_DETAILS_LOADER =
  'SET_COMMERCE_PRODUCT_DETAILS_LOADER'

export const SET_COMMERCE_PRODUCT_DETAILS = 'SET_COMMERCE_PRODUCT_DETAILS'

export const SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES =
  'SET_COMMERCE_PRODUCT_DETAILS_ATTRIBUTES'

export const SET_COMMERCE_PRODUCT_DETAILS_VARIATION =
  'SET_COMMERCE_PRODUCT_DETAILS_VARIATION'

export const SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND =
  'SET_COMMERCE_PRODUCT_DETAILS_CLICKED_TO_SEND'

export const SET_COMMERCE_FILTERS_LOADER = 'SET_COMMERCE_FILTERS_LOADER'

export const SET_COMMERCE_FILTER_ITEMS = 'SET_COMMERCE_FILTER_ITEMS'

export const SET_COMMERCE_FILTERED_ITEMS = 'SET_COMMERCE_FILTERED_ITEMS'

export const SET_COMMERCE_FILTER_QUERY_STRING =
  'SET_COMMERCE_FILTER_QUERY_STRING'

export const SET_COMMERCE_ORDER_BY = 'SET_COMMERCE_ORDER_BY'

export const SET_COMMERCE_SELECTED_PRODUCTS = 'SET_COMMERCE_SELECTED_PRODUCTS'

export const SET_COMMERCE_PRODUCTS_SENDED = 'SET_COMMERCE_PRODUCTS_SENDED'

export const SET_COMMERCE_CART = 'SET_COMMERCE_CART'

export const SET_COMMERCE_CART_LOADER = 'SET_COMMERCE_CART_LOADER'

export const SET_COMMERCE_METHOD_TYPE = 'SET_COMMERCE_METHOD_TYPE'

export const UPDATE_CREATE_CART_PRODUCTS = 'UPDATE_CREATE_CART_PRODUCTS'

export const UPDATE_CHECKOUT_FIELDS = 'UPDATE_CHECKOUT_FIELDS'

export const SET_COMMERCE_CREATE_CART_ORDER = 'SET_COMMERCE_CREATE_CART_ORDER'

export const SET_CATALOG_FILTERS = 'SET_CATALOG_FILTERS'
