import { headers } from '@octadesk-tech/services'

import { CommerceEnum } from '@/modules/Chat/components/conversation/enums/commerce'
import { getBaseClient } from '@/modules/Chat/services/http'

const getCommerceClient = () => getBaseClient('commerce')

export const getCommerceCatalogsService = async () => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get('/catalogs', headers.getAuthorizedHeaders())
    )

    return data.results
  } catch (ex) {
    throw new Error('Error in get Catalogs: ' + ex)
  }
}

export const getCommerceProductsService = async params => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(`/store/products`, {
        params: {
          ...params,
          limit: CommerceEnum.ProductsPerPage
        },
        ...headers.getAuthorizedHeaders()
      })
    )

    return data.results
  } catch (ex) {
    throw new Error('Error in get products: ' + ex)
  }
}

export const getCommerceProductDetailsService = async ({ productId }) => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(`/store/products/${productId}`, headers.getAuthorizedHeaders())
    )

    return data
  } catch (ex) {
    throw new Error('Error in get product details: ' + ex)
  }
}

export const getCommerceProductAttributesCombinationsService = async ({
  productId,
  attributes
}) => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(
        `/store/products/${productId}/variations?attributeValueIds=${attributes}`,
        headers.getAuthorizedHeaders()
      )
    )

    return data
  } catch (ex) {
    throw new Error('Error in get product attributes combinations: ' + ex)
  }
}

export const getCommerceFiltersService = async () => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get('/store/filters', headers.getAuthorizedHeaders())
    )

    return data
  } catch (ex) {
    throw new Error('Error in get filters: ' + ex)
  }
}

export const getCommerceCart = async ({ roomKey, messageId }) => {
  try {
    const { data } = await getCommerceClient().then(client =>
      client.get(
        `/carts/rooms/${roomKey}/messages/${messageId}`,
        headers.getAuthorizedHeaders()
      )
    )

    return data
  } catch (ex) {
    throw new Error('Error in get commerce cart: ' + ex)
  }
}
