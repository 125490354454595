import Axios, { AxiosError, AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'
import {
  AccessRequest,
  AccessRequestResponse
} from '@/common/helpers/interfaces/access-request'
import { AuthPayload } from '@/common/helpers/interfaces/auth-payload'
import { ChangePasswordPayload } from '@/common/helpers/interfaces/change-password-payload'
import { InviteAgentPayload } from '@/common/helpers/interfaces/invite-agent-payload'
import { NewPasswordPayload } from '@/common/helpers/interfaces/new-password-payload'
import { OctaAuthentication } from '@/common/helpers/interfaces/octa-authentication'
import { SignupPayload } from '@/common/helpers/interfaces/signup-payload'
import {
  CreateSSOPayload,
  SSOResponseItem,
  UpdateSSOPayload,
  SSOListResponse
} from '@/common/helpers/interfaces/sso'
import { VerifyAccountPayload } from '@/common/helpers/interfaces/verify-account-payload'

import {
  IApiKey,
  ITransferAndDeletePayload,
  IUserAsResponsible
} from '@/store/interfaces/nucleus-state'

import APIError from '../errors/APIError'
import AuthError from '../errors/AuthError'
import { getAuthState } from './storage'

interface AuthResponse {
  access_token: string
  octaAuthenticated: OctaAuthentication
  roles?: Array<any>
  tenants?: Array<any>
  passwordExpired?: boolean
}

interface UserData {
  tenantId?: string
  userName: string
  password: string
}

export const getNucleusAuthClient = () =>
  Axios.create({
    baseURL:
      import.meta.env.VITE_NUCLEUS_AUTH_API_URL || 'https://us-east1-001.qa.qaoctadesk.com/nucleus-auth'
  })

export const getNucleusClient = () =>
  Axios.create({
    baseURL: import.meta.env.VITE_NUCLEUS_API_URL || 'https://nucleus.qaoctadesk.com/api'
  })

export default class NucleusService {
  private http: AxiosInstance

  private nucleusApi: AxiosInstance

  constructor() {
    this.http = getNucleusAuthClient()

    this.nucleusApi = this.getNucleusAPI()
  }

  getNucleusAPI = () => {
    if (this.nucleusApi) {
      return this.nucleusApi
    }

    this.nucleusApi = getNucleusClient()

    return this.nucleusApi
  }

  headers(): Record<string, string> {
    const access_token = getAuthState()?.access_token

    return {
      'Content-Type': 'application/json',
      ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
    }
  }

  async legacyAuth(token: string): Promise<AuthResponse> {
    try {
      const { data } = await this.http.get(`/auth/fromlegacy?token=${token}`)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at legacyAuth: ' + ex)
    }
  }

  async googleAuth(authPayload: AuthPayload): Promise<AuthResponse> {
    try {
      let apiUrl = `/auth/google?SocialToken=${authPayload.idToken}`

      if (authPayload.tenantId) {
        apiUrl = `/auth/google?SocialToken=${authPayload.idToken}&tenantId=${authPayload.tenantId}`
      }

      const { data } = await this.http.get(apiUrl)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at googleAuth: ' + ex)
    }
  }

  async googleSignup(signupPayload: SignupPayload): Promise<AuthResponse> {
    try {
      const { data } = await this.http.post(`/signup/google`, signupPayload)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at googleSignup: ' + ex)
    }
  }

  async trayAdmins(storeId: string) {
    try {
      const { data } = await this.http.get(`/tray/admins/${storeId}`)

      return data
    } catch (ex) {
      throw new Error('Error at trayAdmins: ' + ex)
    }
  }

  installTrayWidget(isNewWidget?: boolean): Promise<void> {
    const headers = this.headers()

    const urlParams = isNewWidget ? `?newWidget=${isNewWidget}` : ''

    const url = `/tray/widget${urlParams}`

    return this.http.post(
      url,
      {},
      {
        headers
      }
    )
  }

  removeTrayWidget(): Promise<void> {
    const headers = this.headers()

    return this.http.delete(`/tray/widget`, {
      headers
    })
  }

  async trayAuth(signupPayload: SignupPayload) {
    try {
      const { data } = await this.http.post(`/tray/auth`, signupPayload)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response) {
        return response
      }
    }
  }

  async whitelabelAuth(whitelabel: string, jwt: string) {
    try {
      const { data } = await this.http.get(
        `/whitelabel/${whitelabel}/?jwt=${jwt}`
      )

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response) {
        return response
      }
    }
  }

  async linkedinAuth(signupPayload: SignupPayload): Promise<AuthResponse> {
    try {
      let apiUrl = `/auth/linkedin?SocialToken=${signupPayload.socialToken}&RedirectURI=${signupPayload.redirectUri}`

      if (signupPayload.tenantId) {
        apiUrl = `/auth/linkedin?SocialToken=${signupPayload.socialToken}&RedirectURI=${signupPayload.redirectUri}&TenantId=${signupPayload.tenantId}`
      }

      const { data } = await this.http.get(apiUrl)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at linkedinAuth: ' + ex)
    }
  }

  async linkedinSignup(signupPayload: SignupPayload): Promise<AuthResponse> {
    try {
      const { data } = await this.http.post(`/signup/linkedin`, signupPayload)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at linkedinSignup: ' + ex)
    }
  }

  async emailAuth(userData: UserData): Promise<AuthResponse> {
    try {
      const { data } = await this.http.post('/auth', userData)

      return data
    } catch (err) {
      const error = err as AxiosError

      if (error.response?.status === STATUS_CODE.CONFLICT) {
        return error.response.data
      }

      throw new APIError(error)
    }
  }

  async emailSignup(signupPayload: SignupPayload): Promise<AuthResponse> {
    try {
      const { data } = await this.http.post('/signup', signupPayload)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      } else {
        throw new Error('Error at emailSignup: ' + ex)
      }
    }
  }

  async getSSORedirectURL(email: string) {
    try {
      const headers = this.headers()

      const response = await this.http.get('auth/ssoclient/starturls', {
        headers,
        params: {
          email
        }
      })

      return response.data
    } catch (err) {
      const error = new APIError(err as AxiosError)

      throw new AuthError(error)
    }
  }

  async loginSSO(tenantId: string, query: string) {
    try {
      const headers = this.headers()

      const response = await this.http.post(
        'auth/ssoclient/login',
        {
          tenantId,
          queryString: query
        },
        { headers }
      )

      return response.data
    } catch (err) {
      const error = err as AxiosError
      throw new APIError(error)
    }
  }

  async signupAppend(signupPayload: SignupPayload): Promise<AuthResponse> {
    try {
      const headers = this.headers()

      delete signupPayload.miniCluster

      const { data } = await this.http.post('/signup/append', signupPayload, {
        headers
      })

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at emailSignup: ' + ex)
    }
  }

  async sendVerificationPhoneCode(payload: any) {
    try {
      const headers = this.headers()

      const { data } = await this.http.post(
        'signup/sendverificationphonecode',
        payload,
        {
          headers
        }
      )

      return data
    } catch (ex) {
      throw new Error('Error sendVerificationPhoneCode' + ex)
    }
  }

  async verifyPhoneCode(number: any, code: any) {
    try {
      const headers = this.headers()

      const { data } = await this.http.get('signup/verifyphonecode', {
        headers,
        params: {
          number: number,
          code: code
        }
      })

      return data
    } catch (ex) {
      throw new Error('Error verifyPhoneCode' + ex)
    }
  }

  async verifyEmail(email: string): Promise<boolean> {
    try {
      const { status } = await this.http.post('signup/verify', {
        email
      })

      return status === 200
    } catch (ex) {
      throw new Error('Error on verifyEmail: ' + ex)
    }
  }

  async verifyAccount(
    verifyAccountPayload: VerifyAccountPayload
  ): Promise<any> {
    try {
      const { email, token, type, linkedinRedirectUri } = verifyAccountPayload

      let payload = ''

      if (email) {
        payload = `email=${email}`
      } else if (token && type) {
        payload = `${type}token=${token}`

        if (type === 'linkedin' && linkedinRedirectUri) {
          payload += `&linkedinredirecturi=${linkedinRedirectUri}`
        }
      } else {
        throw new Error('missing verifyAccount query params')
      }

      const { data } = await this.http.get(`tenants/verify-account?${payload}`)

      return data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      throw new Error('Error at verifyAccount: ' + ex)
    }
  }

  async requestAccess(requestAccessPayload: AccessRequest): Promise<any> {
    try {
      const { status } = await this.http.post(
        `tenants/${requestAccessPayload.tenantId}/users/request`,
        requestAccessPayload
      )

      return status === 200
    } catch (ex) {
      throw new Error('Error at requestAccess:' + ex)
    }
  }

  async acceptAccessRequest(
    accessRequestResponse: AccessRequestResponse
  ): Promise<any> {
    try {
      const requestParams: any = {}

      const { tokenizedUserInfo, active, tenantId } = accessRequestResponse

      if (tokenizedUserInfo) {
        requestParams.tokenizedUserInfo = tokenizedUserInfo
      }

      if (typeof active !== 'undefined') {
        requestParams.active = active
      }

      const { status } = await this.http.get(
        `tenants/${tenantId}/users/accept`,
        {
          params: {
            ...requestParams
          }
        }
      )

      return status === 200
    } catch (ex) {
      throw new Error('Error at acceptAccess:' + ex)
    }
  }

  async declineAccessRequest(
    accessRequestResponse: AccessRequestResponse
  ): Promise<any> {
    try {
      const requestParams: any = {}

      const { tokenizedUserInfo, active, tenantId } = accessRequestResponse

      if (tokenizedUserInfo) {
        requestParams.tokenizedUserInfo = tokenizedUserInfo
      }

      if (typeof active !== 'undefined') {
        requestParams.active = active
      }

      const { status } = await this.http.get(
        `tenants/${tenantId}/users/decline`,
        {
          params: {
            ...requestParams
          }
        }
      )

      return status === 200
    } catch (ex) {
      throw new Error('Error at declineAccess:' + ex)
    }
  }

  async requestNewPassword(email: string): Promise<any> {
    try {
      if (email) {
        return await this.http.get(`auth/forgot-password`, {
          params: {
            email
          }
        })
      } else {
        throw new Error('Error on requestNewPassword: missing email param')
      }
    } catch (err) {
      const error = err as AxiosError

      throw new APIError(error)
    }
  }

  async createNewPassword(
    newPasswordPayload: NewPasswordPayload
  ): Promise<any> {
    try {
      const response = await this.http.post(
        'auth/update-password',
        newPasswordPayload
      )

      return response.data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response && response.status === STATUS_CODE.CONFLICT) {
        return response.data
      }

      if (response) throw new Error(response.status as unknown as string)
    }
  }

  async changePassword(
    changePasswordPayload: ChangePasswordPayload
  ): Promise<any> {
    try {
      const headers = this.headers()

      const response = await this.http.post(
        'auth/change-password',
        changePasswordPayload,
        { headers }
      )

      return response.data
    } catch (ex) {
      const { response } = ex as AxiosError

      if (response) throw new Error(response.status as unknown as string)
    }
  }

  async inviteAgent(inviteAgentPayload: InviteAgentPayload) {
    const formData = new FormData()

    for (const key in inviteAgentPayload) {
      formData.append(key, inviteAgentPayload[key])
    }

    return await this.http.post('auth/invite-password', formData)
  }

  async createKeyFromFallback(key: string): Promise<any> {
    try {
      const access_token = getAuthState()?.access_token

      if (!access_token) {
        throw new Error(
          'Error on translation key creation: missing access_token'
        )
      }

      const headers = this.headers()

      const response = await this.http.post(
        'translations/json',
        JSON.stringify(key),
        { headers }
      )

      return response.data
    } catch (ex) {
      throw new Error('Error on request:' + ex)
    }
  }

  async getTranslationByLang(lang: string): Promise<any> {
    try {
      const client = services.cdn.getClient()

      const cdnUrl = `/nucleus-i18n/${lang}.json`

      const response = await client.get(cdnUrl)

      return response.data
    } catch (ex) {
      throw new Error('Error on request:' + ex)
    }
  }

  async activateTicket(tenantId: string): Promise<any> {
    try {
      const headers = this.headers()

      return await this.http.get(
        `tenants/${tenantId}/activate-new-octa-ticket`,
        { headers }
      )
    } catch (ex) {
      throw new Error('Error at activateTicket:' + ex)
    }
  }

  async getTenantConfigs(tenantId: string): Promise<any> {
    try {
      const headers = this.headers()

      const { data } = await this.http.get(`tenants/${tenantId}/configs`, {
        headers
      })

      return data.results
    } catch {
      return []
    }
  }

  async listSSOClientOptions(tenantId: string) {
    try {
      const headers = this.headers()

      const response = await this.nucleusApi.get<SSOListResponse>(
        `tenants/${tenantId}/ssoclientoptions`,
        {
          headers
        }
      )

      return response.data.results
    } catch (err) {
      const error = err as AxiosError
      throw new APIError(error)
    }
  }

  async findSSOClientOption(tenantId: string, id: string) {
    try {
      const headers = this.headers()

      const response = await this.nucleusApi.get<SSOResponseItem>(
        `tenants/${tenantId}/ssoclientoptions/${id}`,
        {
          headers
        }
      )

      return response.data
    } catch (err) {
      const error = err as AxiosError
      throw new APIError(error)
    }
  }

  async addSSOClientOption(tenantId: string, payload: CreateSSOPayload) {
    try {
      const headers = this.headers()

      const response = await this.nucleusApi.post<SSOResponseItem>(
        `tenants/${tenantId}/ssoclientoptions`,
        payload,
        {
          headers
        }
      )

      return response.data
    } catch (err) {
      const error = err as AxiosError
      throw new APIError(error)
    }
  }

  async updateSSOClientOption(
    tenantId: string,
    id: string,
    payload: UpdateSSOPayload
  ) {
    try {
      const headers = this.headers()

      await this.nucleusApi.put<null>(
        `tenants/${tenantId}/ssoclientoptions/${id}`,
        payload,
        {
          headers
        }
      )
    } catch (err) {
      const error = err as AxiosError

      throw new APIError(error)
    }
  }

  async removeSSOClientOption(tenantId: string, id: string) {
    try {
      const headers = this.headers()

      await this.nucleusApi.delete(
        `tenants/${tenantId}/ssoclientoptions/${id}`,
        {
          headers
        }
      )
    } catch (err) {
      const error = err as AxiosError

      throw new APIError(error)
    }
  }

  getIsUserResponsible = (
    tenantId: string,
    builtUsersIds: string
  ): Promise<IUserAsResponsible[]> =>
    this.http
      .get(`tenants/${tenantId}/users/is-responsible?${builtUsersIds}`, {
        headers: this.headers()
      })
      .then(response => response.data)

  transferContactsAndDeleteUsers = (
    transferAndDeletePayload: ITransferAndDeletePayload[]
  ) =>
    getNucleusClient()
      .delete(`usersmanagement/users/bulkdelete`, {
        headers: this.headers(),
        data: transferAndDeletePayload
      })
      .then(response => response.data)

  async updateTenantConfigs(
    tenantId: string,
    config: any,
    configId: string
  ): Promise<any> {
    try {
      const headers = this.headers()

      const date: Date = new Date()

      const configs = {
        tenant: {
          id: tenantId
        },
        updatedAt: date.toISOString(),
        ...{ ...config, ...{ id: configId } }
      }

      let data

      if (configId) {
        data = await this.http.put(
          `tenants/${tenantId}/configs/${configId}`,
          { ...configs },
          {
            headers
          }
        )
      } else {
        data = await this.http.post(
          `tenants/${tenantId}/configs/`,
          { ...configs },
          {
            headers
          }
        )
      }

      return data
    } catch {
      return
    }
  }

  getApiKeys = (): Promise<IApiKey[]> =>
    this.http
      .get(`apiKeysManagement/apiKeys`, {
        headers: this.headers()
      })
      .then(response => response?.data?.results)

  createApiKey = (payload: IApiKey): Promise<IApiKey> =>
    this.http
      .post(`apiKeysManagement/apiKeys`, payload, {
        headers: this.headers()
      })
      .then(response => response?.data)

  updateApiKey = (payload: IApiKey): Promise<void> =>
    this.http.put(`apiKeysManagement/apiKeys/${payload.id}`, payload, {
      headers: this.headers()
    })

  deleteApiKey = (apiKeyId: string): Promise<void> =>
    this.http.delete(`apiKeysManagement/apiKeys/${apiKeyId}`, {
      headers: this.headers()
    })

  getApiBaseURL = (): Promise<string> =>
    this.http
      .get(`apiKeysManagement/apikeys-baseurl`, {
        headers: this.headers()
      })
      .then(response => response?.data)

  hasToReregisterCreditCard = (tenantId: string) =>
    this.http
      .get(`Tenants/${tenantId}/paymentmethods/hasToReregisterCreditCard`, {
        headers: this.headers()
      })
      .then(r => r.data)
      .catch(error => {
        console.error(error)
      })

  async editPerson(person: any) {
    try {
      return await this.nucleusApi.put(`legacyuser/${person.id}`, person, {
        headers: {
          'Content-Type': 'application/json',
          appsubdomain: getAuthState().octaAuthenticated?.subDomain,
          authorization: `Bearer ${getAuthState()?.jwtoken}`
        }
      })
    } catch (error) {
      throw new Error('Error at editPerson: ' + error)
    }
  }
}
