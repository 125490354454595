import axios, { AxiosInstance } from 'axios'

import { services } from '@octadesk-tech/services'

import { SurveyConfig } from '@/modules/Ticket/survey/store/interfaces'

import i18n from '@/common/i18n'

import legacyHeaders from '@/common/services/legacy-headers'

import { TicketStatus } from './store/interfaces'

export class TicketService {
  async getClient(): Promise<AxiosInstance> {
    return (await services.createClient('ticket')) as AxiosInstance
  }

  getTicketStatus() {
    return this.getClient()
      .then(client => client.get<TicketStatus[]>('status', legacyHeaders()))
      .then(res => res.data)
  }

  async getConfigurationCustomProperties(clientURL: string, subdomain: string) {
    try {
      const client = axios.create({
        baseURL: clientURL,
        headers: { AppSubDomain: subdomain }
      })
      const { data } = await client.get<SurveyConfig>(
        `/survey-config/custom-properties`
      )
      return data
    } catch (ex) {
      throw new Error(
        `Error in get survey configuration custom properties: ${
          (ex as any).response?.data?.message || (ex as any).message || ''
        }`
      )
    }
  }

  async respondSurvey(
    clientURL: string,
    subdomain: string,
    ticketId: string,
    response: object
  ) {
    try {
      const client = axios.create({
        baseURL: clientURL,
        headers: { AppSubDomain: subdomain }
      })
      const { data } = await client.post(`/survey/respond/${ticketId}`, {
        ticketReference: ticketId,
        ...response
      })
      return data
    } catch (ex) {
      const errorResponse = (ex as any).response?.data
      if (errorResponse?.customCode) {
        throw new Error(
          String(i18n?.t(`surveyFeedback.alerts.${errorResponse.customCode}`))
        )
      }
      throw new Error(String(i18n?.t('surveyFeedback.alerts.DEFAULT')))
    }
  }
}
