import { NavigationGuardNext } from 'vue-router'

import { RoleTypes } from '@/common/helpers/enums/role-types'

import store from '@/store'

const handleRedirectLandingPage = (to: any, next: NavigationGuardNext) => {
  const isTrayWhitelabel = store.getters.isTrayWhitelabel

  const hasWozCopilotFeature = store.getters.isWozEnabled

  const hasWozInBot = store.getters.isWozInBot

  if (isTrayWhitelabel && (!hasWozCopilotFeature || !hasWozInBot)) {
    next({ name: 'home-octa' })
  }

  if (!hasWozCopilotFeature && !hasWozInBot) {
    next({ name: 'woz-lp' })
  }

  next()
}

export default {
  path: '/woz',
  name: 'woz',
  beforeEnter: (to: any, from: any, next: any) => {
    handleRedirectLandingPage(to, next)
  },
  redirect: '/woz/agents/list',
  component: () => import('@/views/Woz.vue'),
  children: [
    {
      path: 'content',
      name: 'content',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      redirect: 'content/list',
      component: () => import('./content/components/Content.vue'),
      children: [
        {
          name: 'woz-content-list',
          path: 'list',
          component: () => import('./content/components/ContentPage.vue')
        },
        {
          name: 'woz-content-gaps',
          path: 'list',
          component: () => import('./content/components/ContentPage.vue')
        },
        {
          name: 'woz-content-item',
          path: ':id',
          component: () => import('./content/components/content/Content.vue')
        }
      ]
    },
    {
      path: 'agents',
      name: 'woz-agents',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      redirect: '/woz/agents/list',
      component: () => import('./agents/components/Agents.vue'),
      children: [
        {
          path: 'list',
          name: 'woz-agents-list',
          component: () => import('./agents/components/list/Agents.vue')
        },
        {
          path: 'edit/:agentId',
          name: 'woz-agents-edit',
          component: () => import('./agents/components/edit/AgentsEdit.vue')
        },
        {
          path: 'new',
          name: 'woz-agents-new',
          component: () => import('./agents/components/edit/AgentsEdit.vue')
        },
        {
          path: 'view/:agentId',
          name: 'woz-agents-view',
          component: () => import('./agents/components/view/AgentsView.vue')
        }
      ]
    },
    {
      path: 'preferences',
      name: 'woz-preferences',
      meta: {
        permissionLevel: RoleTypes.Admin
      },
      component: () => import('./preferences/components/Preferences.vue')
    }
  ]
}
