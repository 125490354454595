import './common/base.css'

import '@octadesk-tech/web-components/dist/style.css'

import './common/plugins'

// Chat-client
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import 'vue-plyr/dist/vue-plyr.css'

import '@lottiefiles/dotlottie-wc'

import tooltip from '@/common/directives/tooltip'
import VueTagsInput from '@johmun/vue-tags-input'
import * as Sentry from '@sentry/vue'
import VueUpload from '@websanova/vue-upload'
import vClickOutside from 'v-click-outside'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueClipboard from 'vue-clipboard2'
import VueCrontab from 'vue-crontab'
import VueLazyload from 'vue-lazyload'
import VueObserveVisibility from 'vue-observe-visibility'
import VuePlyr from 'vue-plyr'
import VueScrollTo from 'vue-scrollto'
import VueVirtualScroller from 'vue-virtual-scroller'
import Vue2TouchEvents from 'vue2-touch-events'

import filters from '@/modules/Chat/components/conversation/filters'
import { createClient } from '@/modules/Chat/services/http'

import SanitizeHtmlMixin from '@/common/helpers/mixins/sanitize-html.mixin'

import { setHttpLink, getApolloClient } from './common/apolloClient'
import App from './common/App.vue'
import bootstrap from './common/bootstrap'
import i18n from './common/i18n'
import router from './routes/router'
import store from './store'

Vue.config.silent = false

Vue.config.productionTip = false

Vue.mixin(SanitizeHtmlMixin)

const initApplication = async () => {
  Vue.use(vClickOutside)

  Vue.use(VueScrollTo)

  Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
  })

  Vue.use(tooltip)

  await setHttpLink()

  const apolloProvider = new VueApollo({
    defaultClient: getApolloClient()
  })

  Vue.use(VueApollo)

  // Chat-client
  Vue.use(Vue2TouchEvents)

  Vue.use(VueClipboard)

  Vue.use(VueVirtualScroller)

  Vue.use(VuePlyr)

  Vue.use(VueCrontab)

  Vue.use(VueObserveVisibility)

  Vue.use(VueUpload, {
    http: createClient()
  })

  Vue.component('VueTagsInput', VueTagsInput)

  const app = new Vue({
    i18n,
    router,
    store,
    filters,
    apolloProvider,
    render: h => h(App)
  })

  if (navigator.webdriver) {
    window.automation = {
      app
    }
  }

  const host = import.meta.env.VITE_HOST || 'app.qaoctadesk.com'

  const isProduction = host === 'app.octadesk.com'

  if (isProduction) {
    try {
      Sentry.init({
        Vue,
        dsn: 'https://6787bd5b1c1560f51c1f1a28aa303996@o4508003175759872.ingest.us.sentry.io/4508003186900992',
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 0.25,
        tracePropagationTargets: [/^https:\/\/app\.octadesk\.com/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.5
      })
    } catch (error) {
      console.error('Error initializing Sentry', error)
    }
  }

  bootstrap(app, Vue, store, router).then(() => {
    app.$mount('#app')
  })
}

initApplication()
